div.toc {
  /*display: inline-block;*/
  white-space: nowrap;
  border: 2px solid #999;
  padding: 15px 20px 0px 40px;
  margin: 15px 0px 20px 30px;
  float: right;

  ol, ul {
    list-style-position: outside;
  }
}

.toc-title {
  margin: 0px 20px 15px 0px;
  text-align: center;
}
